import { requestApi } from '../../helpers/api'
import { COOPERATION_OPTS, LOYALTY_OPTS } from './constants'
import { DashboardApiResponseType, DashboardCategoryFilterType } from './types'

export const dashboardEndpoints = {
  getDashboard: function (
    regionCodeDashboard: string | string[],
    loyaltyDashboard: number[],
    mediaTypesDashboard: number[],
    mediaTypesDashboardDefault: string,
    cooperationDashboard: number[],
  ): Promise<{ regions: Record<string, unknown> }> {
    if (regionCodeDashboard.length === 0) {
      return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolve([])
      })
    }

    const checkParam = (p, opts) => {
      if (p && p.length > 0) {
        return p.join(',')
      } else {
        return opts.map((obj) => obj.value).join(',')
      }
    }

    return requestApi<{ regions: Record<string, unknown> }>(
      'get',
      '/dashboard/v3',
      {
        regionCodeString: Array.isArray(regionCodeDashboard)
          ? regionCodeDashboard.join(',')
          : regionCodeDashboard,
        loyalties: checkParam(loyaltyDashboard, LOYALTY_OPTS),
        sectionTypes: mediaTypesDashboard
          ? mediaTypesDashboard.join(',')
          : mediaTypesDashboardDefault,
        partnerships: checkParam(cooperationDashboard, COOPERATION_OPTS),
      },
    )
  },
  getSummaryDiagram: function (
    regionCode: string | string[],
    startDate: string,
    endDate: string,
    mediaTypes: number[],
    mediaTypesDashboardDefault: string,
  ): Promise<DashboardApiResponseType> | [] {
    if (regionCode.length === 0) {
      return new Promise((resolve) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolve([])
      })
    }
    return requestApi<DashboardApiResponseType>('get', '/dashboard/summary', {
      regionCodes: Array.isArray(regionCode)
        ? regionCode.join(',')
        : regionCode,
      startDate,
      endDate,
      sectionTypes: mediaTypes
        ? mediaTypes.join(',')
        : mediaTypesDashboardDefault,
    })
  },
  getExcelReport: function (
    regionCodes: string,
    sectionType: number,
    fields: string,
    organizationId: number,
  ): Promise<void> {
    // если в последовательности регионов разделителем является
    // точка то изменить ее на запятую, иначе скаченный файл будет с ошибкой
    const regionCodeConvert = regionCodes.replace(/\./g, ',')
    // получить ответ от сервера
    return requestApi<void>(
      'get',
      '/files/getinfocardv2',
      {
        regionCodes: regionCodeConvert,
        sectionType,
        fields,
        organizationId,
      },
      true,
      {
        responseType: 'blob',
      },
    )
  },
  getExcelReportAll: function (
    regionCodes: string,
    fields: string,
  ): Promise<void> {
    return requestApi<void>(
      'get',
      '/files/getinfocardv2',
      { regionCodes, fields },
      true,
      {
        responseType: 'blob',
      },
    )
  },
  getCategoryFilter: function (): Promise<DashboardCategoryFilterType> {
    return requestApi('get', '/mediaTypes')
  },
}
