import { SidebarLinkProps } from './types'
import {
  COLUMNS_CRK_PERSON,
  COLUMNS_CRK_SOCIAL,
  COLUMNS_MEDIA,
  COLUMNS_MESSENGERS,
  COLUMNS_NEW_WAVE_POLITICS,
  COLUMNS_PERSON,
  COLUMNS_SOCIAL,
  COLUMNS_TOP30,
  MediaType,
  UserOrganization,
} from '../section/constants'

export const ROUTES: SidebarLinkProps[] = [
  {
    name: 'Дашборд',
    route: '/',
    icon: '/static/sidebar/dashboard.svg',
    code: 'dashboard',
    organization: null,
    columns: null,
  },
  {
    name: 'Новости',
    route: '/section/news',
    icon: '/static/sidebar/news.svg',
    code: 'news',
    mediaType: MediaType.News,
    organization: UserOrganization.Analytics,
    columns: COLUMNS_SOCIAL,
    topicType: 1,
  },
  // {
  //   name: 'Обсуждения',
  //   route: '/section/discussions',
  //   icon: '/static/sidebar/discussions.svg',
  //   code: 'discussions',
  //   organization: UserOrganization.Analytics,
  //   mediaType: MediaType.Discussion,
  //   columns: COLUMNS_SOCIAL,
  //   topicType: 1,
  // },
  {
    name: 'СМИ',
    route: '/section/media',
    icon: '/static/sidebar/media.svg',
    code: 'media',
    organization: UserOrganization.Analytics,
    mediaType: MediaType.Smi,
    columns: COLUMNS_MEDIA,
    topicType: 1,
  },
  {
    name: 'Блоги',
    route: '/section/blogs',
    icon: '/static/sidebar/blogs.svg',
    code: 'blogs',
    organization: UserOrganization.Analytics,
    mediaType: MediaType.Blog,
    columns: COLUMNS_SOCIAL,
    topicType: 1,
  },
  {
    name: 'Инфлюенсеры',
    route: '/section/influencers',
    icon: '/static/sidebar/influencers.svg',
    code: 'influencers',
    organization: UserOrganization.Analytics,
    mediaType: MediaType.Influencer,
    columns: COLUMNS_PERSON,
    topicType: 1,
  },
  {
    name: 'Лидеры мнений',
    route: '/section/liders',
    icon: '/static/sidebar/liders.svg',
    code: 'liders',
    organization: UserOrganization.Analytics,
    mediaType: MediaType.Leader,
    columns: COLUMNS_PERSON,
    topicType: 1,
  },
  {
    name: 'Паблики ЦРК',
    route: '/section/CrkSocialNetworks',
    icon: '/static/sidebar/crk_social_networks.svg',
    code: 'CrkSocialNetworks',
    organization: UserOrganization.Crk,
    mediaType: MediaType.CrkSocialNetworks,
    columns: COLUMNS_CRK_SOCIAL,
    topicType: 2,
  },
  {
    name: 'Блогеры ЦРК',
    route: '/section/CrkBloggers',
    icon: '/static/sidebar/crk_bloggers.svg',
    code: 'CrkBloggers',
    organization: UserOrganization.Crk,
    mediaType: MediaType.CrkBloggers,
    columns: COLUMNS_CRK_PERSON,
    topicType: 3,
  },
  {
    name: 'Общественные организации',
    route: '/section/organizations',
    icon: '/static/sidebar/organizations.svg',
    code: 'organizations',
    organization: UserOrganization.Analytics,
    mediaType: MediaType.PublicOrganization,
    columns: COLUMNS_SOCIAL,
    topicType: 1,
  },
  {
    name: 'ТОП Паблики',
    route: '/section/toppublics',
    icon: '/static/sidebar/top_publics.svg',
    code: 'toppublics',
    organization: UserOrganization.Analytics,
    mediaType: MediaType.TopPublic,
    columns: COLUMNS_SOCIAL,
    topicType: 1,
  },
  {
    name: 'Мессенджеры',
    route: '/section/messengers',
    icon: '/static/sidebar/discussions.svg',
    code: 'messengers',
    organization: UserOrganization.Crk,
    mediaType: MediaType.Messengers,
    columns: COLUMNS_MESSENGERS,
    topicType: 2,
  },
  {
    name: 'Политики новой волны',
    route: '/section/newWavePolitics',
    icon: '/static/sidebar/influencers.svg',
    code: 'newWavePolitics',
    organization: UserOrganization.Crk,
    mediaType: MediaType.NewWavePolitics,
    columns: COLUMNS_NEW_WAVE_POLITICS,
    topicType: 2,
  },
  {
    name: 'ТОП-30',
    route: '/section/top30',
    icon: '/static/sidebar/top_publics.svg',
    code: 'top30',
    organization: UserOrganization.Crk,
    mediaType: MediaType.Top30,
    columns: COLUMNS_TOP30,
    topicType: 2,
  },
]

export const OrganizationOpts = [
  {
    label: 'Аналитики',
    value: UserOrganization.Analytics,
  },
  {
    label: 'ЦРК',
    value: UserOrganization.Crk,
  },
]
