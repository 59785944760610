import { TopicType } from '../../store/models/dictionary'

export type TableDataType = SectionRowType[]

export type SectionRowType = {
  id: number
  groupName: string
  socialNetworks: SectionSocialNetwork[]
  loyalty: number
  topics: TopicType[]
  crkBloggerTopics: TopicType[]
  crkPublicTopics: TopicType[]
  owners: OwnerType[]
  partnership: number
  crkAdministrationPartnership: number
  regionCode: string
  editMode?: number
  canUpdate?: boolean
  sectionType: number
  contentType: number
  category: number[] | number
  comment: string
  district: string
  totalSubscribers: number
  totalViews: number
  totalSi: number
  topChannel: boolean
  channelLevel: number
  ratingValue: number
  ratingPlace: number
  isMonitoring: boolean
  isSocialFieldControl: boolean
}

export type SectionApiResponse = {
  items: SectionRowType[]
  hasMore: boolean
  instagramUpdateDate: string
}

export type SectionSocialNetwork = {
  id: number
  link: string
  type: number
  subscriberCount: number
  citationIndex: number
  publicationFrequency: number
  er: number
  si?: number
  viewsEr: number
  averageViewsCount: number
  problem: number
  isHidden: boolean
  lastUpdateDate: string
  createDate: string
  updateDate: string
}

export type OwnerType = {
  name: string
  link: string
}

export type ColumnType = {
  name: string
  code: string
  sortable: boolean
}

export enum SortDirection {
  ASC,
  DESC,
}

export type SectionPayload = {
  sectionType: number
  regionCode?: string[]
  regionCodeDashboard?: string | string[]
  category?: number[]
  mediaTypesDashboard?: number[]
  loyalty?: number
  loyaltyAnalytics?: number[]
  loyaltyDashboard?: number[]
  typetask?: string[]
  startDate?: string
  endDate?: string
  startDateDashboard?: string
  endDateDashboard?: string
  socialNetwork?: number[]
  areaNetwork?: number[]
  topics?: string[]
  skip?: number
  isMonitoring?: string
  isSocialFieldControl?: string
  period?: string[]
  hasOwner?: boolean
  partnership?: number[]
  cooperationDashboard?: number[]
}

export type CategoryType = {
  id: number
  name: string
}

export type CategoryTypeApiResponse = CategoryType[]

export type SearchResultType = {
  items: SearchResultItemType[]
  hasMore: boolean
}

export type SearchResultItemType = {
  id: number
  groupName: string
  loyalty: number
  partnership: number
  sectionType: number
  category: number
  contentType: number
  regionCode: string
  topics: TopicType[]
  owners: OwnerType[]
  socialNetworks: SectionSocialNetwork[]
}

export type SearchPayloadType = {
  text: string
  fullResult?: boolean
  categories?: number
  topics?: TopicType[]
  crkPublicTopics?: TopicType[]
  crkBloggerTopics?: number
  skip?: number
  organizationId: number
}

export enum TopicsTypes {
  'topics' = 1,
  'crkPublicTopics' = 2,
  'crkBloggerTopics' = 3,
}

export type ColumnEditType = {
  editMode: boolean
  updateRow?: (columnName, value) => void
}
