import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { DictionaryType, Region } from '../../store/models/dictionary'
import {
  BlogCategoriesOpts,
  DISTRICTS,
  LOYALTY_OBJ,
  SmiCategoriesOpts,
  UserFlags,
} from './constants'
import { useMemo, useState } from 'react'
import {
  SectionPayload,
  SectionRowType,
  SortDirection,
  TableDataType,
} from './types'
import { checkAccess } from '../../helpers/user'
import { OrganizationOpts, ROUTES } from '../sidebar/constants'
import { getTopicsOpts, getTopicsOptsName } from './helpers'
import { UserType } from '../users/types'
import { UserState } from '../../store/models/user'

export const useColsEnabled = (sectionName: string): string[] => {
  const colsEnabled = useSelector<RootState, Record<string, string[]>>(
    (state) => state.general.colsEnabled,
  )
  const colsType = ROUTES.find((item) => item.code === sectionName).code

  return colsEnabled[colsType]
}

export const useRegionsOpts = (): Region[] => {
  const regions = useSelector<RootState, Region[]>(
    (state) => state.dictionary.regions,
  )
  return useMemo(() => {
    regions.sort((a, b) => a.regionName.localeCompare(b.regionName))
    return regions
  }, [])
}

export const useFilterOpts = (
  sectionName: string,
): [Region[], Record<number, string>[], Record<number, string>[]] => {
  let categories
  switch (sectionName) {
    case 'blogs':
      categories = BlogCategoriesOpts
      break
    case 'media':
      categories = SmiCategoriesOpts
      break
    default:
      categories = null
      break
  }

  const regions = useSelector<RootState, Region[]>(
    (state) => state.dictionary.regions,
  )
  regions.sort((a, b) => a.regionName.localeCompare(b.regionName))

  const organization = useSelector<RootState, number>(
    (state) => state.section.organization,
  )
  const visibleRegions = useSelector<RootState, string[]>(
    (state) => state.user.organizationAccess[organization]?.visibleRegions,
  )
  const canSeeAllRegions = checkAccess(UserFlags.CanSeeAllRegions)

  const regionsFiltered = canSeeAllRegions
    ? regions
    : regions.filter((item) => visibleRegions?.includes(item.regionCode))

  const topics = getTopicsOpts(sectionName)

  return [regionsFiltered, categories, topics]
}

export const useLoyaltyOpts = (): DictionaryType => {
  return useMemo(() => {
    const result = []
    for (const index in LOYALTY_OBJ) {
      result.push({
        value: parseInt(index),
        label: LOYALTY_OBJ[index].label,
      })
    }
    return result
  }, [])
}

export const useSortTable = (
  table: SectionRowType[],
): [
  string,
  (value: string) => void,
  SortDirection,
  (value: SortDirection) => void,
  SectionRowType[],
] => {
  const [sortCol, setSortCol] = useState(null)
  const [sortDirection, setSortDirection] = useState<SortDirection>(null)

  const sortedTable = useMemo(() => {
    if (sortCol === null || sortDirection === null) {
      return [...table]
    }
    let direction = SortDirection.ASC
    if (sortDirection) {
      direction = sortDirection
    }

    //todo refactor
    if (
      ['subscriberCount', 'er', 'viewsEr', 'averageViewsCount'].includes(
        sortCol,
      )
    ) {
      return table.sort((item1, item2) => {
        if (
          item1['socialNetworks'][0][sortCol] ===
          item2['socialNetworks'][0][sortCol]
        ) {
          return 0
        }
        const result =
          item1['socialNetworks'][0][sortCol] >
          item2['socialNetworks'][0][sortCol]
            ? 1
            : -1
        return result * (direction === SortDirection.ASC ? 1 : -1)
      })
    } else {
      return table.sort((item1, item2) => {
        if (item1[sortCol] === item2[sortCol]) {
          return 0
        }
        const result = item1[sortCol] > item2[sortCol] ? 1 : -1
        return result * (direction === SortDirection.ASC ? 1 : -1)
      })
    }
  }, [sortCol, sortDirection, table])
  return [sortCol, setSortCol, sortDirection, setSortDirection, sortedTable]
}

export const useMapRegions = (): Record<string, string> => {
  return useMemo(() => {
    const regions = {}
    DISTRICTS.forEach((district) => {
      // добавить код и название округов
      regions[district.code] = district.name
      district.regions.forEach((region) => {
        // добавить код и название регионов
        regions[region.code] = region.name
      })
    })
    return regions
  }, [])
}

export const useFilterSection = (
  data: TableDataType,
  sectionName: string,
): TableDataType => {
  const filters = useSelector<RootState, SectionPayload>(
    (state) => state.section.filters,
  )
  const topicsOptsName = getTopicsOptsName(sectionName)
  return useMemo(() => {
    let filtered = data

    if (filters.socialNetwork?.length)
      filtered = filtered.reduce((agg, row) => {
        const socials = []
        row.socialNetworks.forEach((social) => {
          if (filters.socialNetwork.includes(social.type)) {
            socials.push(social)
          }
        })
        if (socials.length) agg.push({ ...row, socialNetworks: socials })
        return agg
      }, [])

    if (filters.loyalty !== null)
      filtered = filtered.filter((row) => row.loyalty === filters.loyalty)

    if (filters.topics?.length) {
      filtered = filtered.filter((row) =>
        row[topicsOptsName].some((topic) => filters.topics.includes(topic.id)),
      )
    }

    return filtered
  }, [data, filters.socialNetwork, filters.loyalty, filters.topics])
}

// eslint-disable-next-line
export const useRegionsTreeOpts = (all = false) => {
  const user = useSelector<RootState, UserType>((state) => state.user)
  const organization = useSelector<RootState, number>(
    (state) => state.section.organization,
  )
  const visibleRegions = user.isSuperuser
    ? []
    : user.organizationAccess[organization]?.visibleRegions
  const canSeeAllRegions = checkAccess(UserFlags.CanSeeAllRegions)
  const allValues = []

  return useMemo(() => {
    const opts = DISTRICTS.reduce((result, district) => {
      const children = district.regions.reduce((regions, region) => {
        if (
          (visibleRegions && visibleRegions.includes(region.code)) ||
          user.isSuperuser ||
          canSeeAllRegions ||
          all
        ) {
          regions.push({
            label: region.name,
            value: region.code,
          })
        }
        return regions
      }, [])
      if (children.length) {
        const districtValues = children.map((item) => item.value).join()
        allValues.push(districtValues)
        result.push({
          label: district.name,
          value: districtValues,
          children: children,
        })
      } else {
        allValues.push(district.code)
        result.push({
          label: district.name,
          value: district.code,
          children: [],
        })
      }
      return result
    }, [])
    return [{ label: 'Все', value: allValues.join(), children: opts }]
  }, [organization])
}

// eslint-disable-next-line
export const useUserOrganizationOpts = () => {
  const user = useSelector<RootState, UserState>((state) => state.user)
  if (user.isSuperuser) {
    return OrganizationOpts
  }
  return useMemo(() => {
    const userOrgAccess = Object.keys(user.organizationAccess)
    return OrganizationOpts.filter((opt) =>
      userOrgAccess.includes(opt.value.toString()),
    )
  }, [])
}
