import { styled, StyledComponent } from 'linaria/react'

type Props = {
  direction?: 'row' | 'column' | 'row-reverse'
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  align?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
  margin?: string
  padding?: string
  width?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: React.MutableRefObject<any>
}

export const Wrapper: StyledComponent<Props> = styled.div`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  flex-direction: ${(p) => p.direction};
  justify-content: ${(p) => p.justify};
  align-items: ${(p) => p.align};
  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};
`

Wrapper.defaultProps = {
  direction: 'row',
  align: 'center',
  justify: 'center',
  padding: '0',
  margin: '0',
  width: '100%',
}
