import { Store } from '../../store/with-redux-store'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { TopicsDictionaryType } from '../../store/models/dictionary'
import { TopicsTypes } from './types'
import { ROUTES } from '../sidebar/constants'

export const isMultiRegion = (reduxStore: Store): boolean => {
  if (reduxStore) {
    const regionCode = reduxStore.getState().section.filters.regionCode
    if (!!regionCode && Array.isArray(regionCode) && regionCode.length > 0) {
      return regionCode[0].split(',').length > 1 || regionCode.length > 1
    } else {
      return false
    }
  }
}

export const getTopicsOptsName = (sectionName: string): string => {
  const topicType = ROUTES.find((route) => route.code === sectionName)
    ?.topicType

  return TopicsTypes[topicType]
}

export const getTopicsOpts = (
  sectionName: string,
): Record<number, string>[] => {
  const topicsDic = useSelector<RootState, TopicsDictionaryType>(
    (state) => state.dictionary.topics,
  )
  const topicType = ROUTES.find((route) => route.code === sectionName)
    ?.topicType

  return topicsDic.topics[topicType]
}
