export const TEXT_COLOR = '#222222'
export const PRIMARY_COLOR = '#4AB183'
export const SECONDARY_COLOR = '#4566f6'
export const ERROR_COLOR = '#E24B4B'
export const PRIMARY_TEXT_COLOR = '#ffffff'
export const DEFAULT_TEXT_COLOR = '#727382'
export const PRIMARY_BACKGROUND = '#444444'
export const DEFAULT_BACKGROUND = '#25262A'
export const LOGIN_BACKGROUND = '#FFFFFF'
export const TILE_BACKGROUND = '#f8f3e8'

export const BUTTON_TYPES_TO_COLORS = {
  primary: '#ffffff',
  dark: '#ffffff',
  default: DEFAULT_TEXT_COLOR,
  flat: DEFAULT_TEXT_COLOR,
}
export const BUTTON_TYPES_TO_BACKGROUNDS = {
  default: '#EEF1FA',
  dark: '#36373C',
  flat: '#ffffff',
  primary: '#4566f6',
}

export const SIDEBAR_WIDTH = 340
export const PAGE_WRAPPER_PADDING = 0
export const SEARCH_LOCALE = {
  searchPlaceholder: 'Поиск',
  noResultsText: 'Ничего не найдено',
}
