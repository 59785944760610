import { useCallback, useEffect, useRef, useState } from 'react'

export const useDebouncedEffect = (
  callback: () => void,
  delay: number,
  deps = [],
): void => {
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    const handler = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, ...deps])
}

export const useMemoizedFn = (
  fn: (...args: unknown[]) => unknown,
  params: unknown[],
): ((...args: unknown[]) => unknown) => {
  return useCallback(() => {
    return fn(...params)
  }, [...params])
}

export const useAsyncEffect = (
  fn: (...args: unknown[]) => unknown,
  params: unknown[],
  initResult: unknown,
): [unknown, (v) => void, boolean, boolean] => {
  const [result, setResult] = useState(initResult)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const memoizedFn = useMemoizedFn(fn, params)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const data = await memoizedFn()
        setResult(data)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [memoizedFn])

  return [result, setResult, loading, error]
}

export const useUpdateEffect = (
  fn: () => void,
  params: Array<unknown>,
): void => {
  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {
      fn()
    } else {
      didMountRef.current = true
    }
  }, params)
}

export const useInterval = (
  callback: () => void,
  delay: number | null,
): void => {
  const savedCallback = useRef<() => void | null>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
