export function pluralize(
  number: number,
  one: string,
  two: string,
  five: string,
): string {
  const n100 = Math.abs(number) % 100

  if (n100 >= 5 && n100 <= 20) return five

  const n10 = n100 % 10

  if (n10 === 1) return one
  if (n10 >= 2 && n10 <= 4) return two

  return five
}

export function secondsLeftToString(secondsLeft: number): string {
  let result = ''
  if (secondsLeft > 60) {
    let minutesLeft = Math.floor(secondsLeft / 60)

    if (minutesLeft > 60) {
      const hoursLeft = Math.floor(minutesLeft / 60)
      result += `${hoursLeft} ${pluralize(hoursLeft, 'час', 'часа', 'часов')} `
      minutesLeft = minutesLeft % 60
    }
    result += `${minutesLeft} ${pluralize(
      minutesLeft,
      'минута',
      'минуты',
      'минут',
    )} `
    secondsLeft = secondsLeft % 60
  }

  return (
    result +
    `${secondsLeft} ${pluralize(secondsLeft, 'секунда', 'секунды', 'секунд')}`
  )
}

export function addHttp(link: string): string {
  return !/^https?:\/\//i.test(link) ? 'http://' + link : link
}

export function thousands(num: number): string {
  const num_parts = num.toString().split('.')
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return num_parts.join('.')
}
