import React from 'react'
import { styled, StyledComponent } from 'linaria/react'

import {
  BUTTON_TYPES_TO_BACKGROUNDS,
  BUTTON_TYPES_TO_COLORS,
} from './constants'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: 'primary' | 'dark' | 'default' | 'flat'
}
export const Button: StyledComponent<ButtonProps> = styled.button`
  background: ${({ appearance }) => BUTTON_TYPES_TO_BACKGROUNDS[appearance]};
  color: ${({ appearance }) => BUTTON_TYPES_TO_COLORS[appearance]};
  border-color: ${({ appearance }) => BUTTON_TYPES_TO_COLORS[appearance]};
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  line-height: 16px;
  padding: 10px 20px;
  &:focus {
    outline: 0;
  }
`

Button.defaultProps = {
  appearance: 'default',
}
