export enum PublicGroupType {
  Vk = 0,
  Facebook = 1,
  Instagram = 2,
  Odnoklassniki = 3,
  Youtube = 4,
  Telegram = 5,
  Twitter = 6,
  Tiktok = 7,
  Viber = 8,
  YandexZen = 9,
  Rutube = 10,
  Smi = 11,
}

export const CHART = [
  {
    title: 'Вконтакте',
    type: 'vk',
    icon: '/static/diagram/d_vk.svg',
    color: '#3A6AA1',
    groupType: PublicGroupType.Vk,
  },
  {
    title: 'Facebook',
    type: 'facebook',
    icon: '/static/diagram/d_fe.svg',
    color: '#22436F',
    groupType: PublicGroupType.Facebook,
  },
  {
    title: 'Instagram',
    type: 'instagram',
    icon: '/static/diagram/d_in.svg',
    color: '#c23290',
    groupType: PublicGroupType.Instagram,
  },
  {
    title: 'Одноклассники',
    type: 'odnoklassniki',
    icon: '/static/diagram/d_od.svg',
    color: '#F7931E',
    groupType: PublicGroupType.Odnoklassniki,
  },
  {
    title: 'Youtube',
    type: 'youtube',
    icon: '/static/diagram/d_yu.svg',
    color: '#F44336',
    groupType: PublicGroupType.Youtube,
  },
  {
    title: 'Telegram',
    type: 'telegram',
    icon: '/static/diagram/d_tg.svg',
    color: '#0a83be',
    groupType: PublicGroupType.Telegram,
  },
  {
    title: 'Twitter',
    type: 'twitter',
    icon: '/static/diagram/d_tw.svg',
    color: '#06a9f4',
    groupType: PublicGroupType.Twitter,
  },
  {
    title: 'TikTok',
    type: 'tiktok',
    icon: '/static/diagram/d_tk.svg',
    color: '#000000',
    groupType: PublicGroupType.Tiktok,
  },
  {
    title: 'Viber',
    type: 'viber',
    icon: '/static/diagram/d_vb.svg',
    color: '#6A42A8',
    groupType: PublicGroupType.Viber,
  },
  {
    title: 'ЯндексДзен',
    type: 'yandexZen',
    icon: '/static/diagram/d_zn.svg',
    color: '#151515',
    groupType: PublicGroupType.YandexZen,
  },
  {
    title: 'Rutube',
    type: 'rutube',
    icon: '/static/diagram/d_ru.svg',
    color: '#12232b',
    groupType: PublicGroupType.Rutube,
  },
  {
    title: 'СМИ',
    type: 'massMedia',
    icon: '/static/diagram/d_smi.svg',
    color: '#E5F1FB',
    groupType: PublicGroupType.Smi,
  },
]

export enum LoyaltyType {
  Positive = 0,
  Neutral = 1,
  Negative = 2,
  Unknown = 3,
}

export const TONALITY_OPTS = [
  {
    label: 'Позитив',
    value: LoyaltyType.Positive,
  },
  {
    label: 'Негатив',
    value: LoyaltyType.Negative,
  },
  {
    label: 'Нейтральный',
    value: LoyaltyType.Neutral,
  },
]

export const LOYALTY_OPTS = [
  {
    label: 'Позитив',
    value: LoyaltyType.Positive,
  },
  {
    label: 'Негатив',
    value: LoyaltyType.Negative,
  },
  {
    label: 'Нейтральный',
    value: LoyaltyType.Neutral,
  },
  {
    label: 'Неизвестно',
    value: LoyaltyType.Unknown,
  },
]

export const COOPERATION_OPTS = [
  {
    label: 'Возможно',
    value: 0,
  },
  {
    label: 'Отказ',
    value: 1,
  },
  {
    label: 'Неизвестно',
    value: 2,
  },
]

export const TYPE_TASK_OPTS = [
  {
    label: 'Мониторинг',
    value: 'isMonitoring',
  },
  {
    label: 'Контроль инфополя',
    value: 'isSocialFieldControl',
  },
]

export const CARDS_SOCIAL = [
  {
    title: 'Вконтакте',
    icon: '/static/filters/f_vk.svg',
    iconDiagram: '/static/filters/f_vk.svg',
    groupType: PublicGroupType.Vk,
    dataTitle: 'vkontakte',
    erTitle: 'vkontakteEr',
    viewsErTitle: 'vkontakteViewsEr',
  },
  {
    title: 'Одноклассники',
    icon: '/static/filters/f_od.svg',
    groupType: PublicGroupType.Odnoklassniki,
    dataTitle: 'odnoklassniki',
  },
  {
    title: 'Instagram',
    icon: '/static/filters/f_in.svg',
    groupType: PublicGroupType.Instagram,
    dataTitle: 'instagram',
    erTitle: 'instagramEr',
    viewsErTitle: 'instagramViewsEr',
  },
  {
    title: 'Telegram',
    icon: '/static/filters/f_tg.svg',
    groupType: PublicGroupType.Telegram,
    dataTitle: 'telegram',
  },
  {
    title: 'Youtube',
    icon: '/static/filters/f_yu.svg',
    groupType: PublicGroupType.Youtube,
    dataTitle: 'youtube',
  },
  {
    title: 'Facebook',
    icon: '/static/filters/f_fe.svg',
    groupType: PublicGroupType.Facebook,
    dataTitle: 'facebook',
  },
  {
    title: 'TikTok',
    icon: '/static/diagram/d_tk.svg',
    groupType: PublicGroupType.Tiktok,
    dataTitle: 'tiktok',
  },
  {
    title: 'Viber',
    icon: '/static/diagram/d_vb.svg',
    groupType: PublicGroupType.Viber,
    dataTitle: 'viber',
  },
  {
    title: 'YandexZen',
    icon: '/static/diagram/d_zn.svg',
    groupType: PublicGroupType.YandexZen,
    dataTitle: 'yandexZen',
  },
  {
    title: 'Twitter',
    icon: '/static/diagram/d_tw.svg',
    groupType: PublicGroupType.Twitter,
    dataTitle: 'twitter',
  },
  {
    title: 'Rutube',
    icon: '/static/diagram/d_ru.svg',
    groupType: PublicGroupType.Rutube,
    dataTitle: 'rutube',
  },
  {
    title: 'СМИ',
    icon: '/static/diagram/d_smi.svg',
    groupType: PublicGroupType.Smi,
    dataTitle: 'smi',
  },
]
